.graphics{
    display: flex;
    grid-area: main;
    border: solid;
    border-color: var(--light-grey-half);
    border-width: 1px;
    flex-direction: column;
}

.graphics-header{
    display: grid;
    grid-template-areas: 'rtn sch';
    grid-template-columns: 80px auto;
    background-color: orange;
    height: 3rem;
}

.graphics-header-options{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.graphics-header-return{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.graphics-display{
    display: flex;
    justify-content: center;
    align-items: center;
}

.local-selector{
    height: 2rem;
}