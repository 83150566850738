.grid {
  box-sizing: border-box;
  text-align: center;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  /* grid-template-columns: 200px calc(100vw - 200px); */
  grid-template-rows: 35px  calc(100vh - 35px);
  width: 100%;
  gap: 0px;
  background-color: white;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  /* width: 100vw; */
}
.grid2{
  box-sizing: border-box;
  text-align: center;
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: 50px  auto;

  gap: 0px;
  background-color: white;
  padding: 0px;
  margin: 0px;
}

.App{
  height: 100vh;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 840px) {
  .grid{
    display: flex;
    flex-direction: column;
  }
}