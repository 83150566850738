.bigcard{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    /* flex-wrap: wrap; */
    
    /* max-width: 611px; */
    /* min-height: 200px; */
    width: 100%;
    margin-left: 5px;
}

.border{
    border: solid;
    border-width: 1px;
    border-color: var(--light-grey-half);

}

.bigcard-info{
    display: flex;
    width: 100%;
    min-height: 120px;
    grid-template-areas:
        'sbn wrn';
}
.bigcard-header{
    width: 100%;
    box-sizing: border-box;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bigcard-header button{
    margin: 10px;
    height: 1.5rem;
}

.sb-data{
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* max-height: 150px;; */

    justify-content: flex-start;
    align-items: flex-start;
}

.warnings{
    box-sizing: border-box;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#sb-list{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 250px;
    max-height: 150px;
}