#home{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.show-stoppage{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  
}

#grid-content{
  overflow-y: auto;
}

.stoppage-content{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}


.plant-stoppages{
  box-sizing: border-box;
  border: solid;
  border-width: 1px;
  border-color: var(--light-grey-half);
  display: flex;
  width: 500px;
  margin: 0.1rem;
  padding: 10px;
  flex-direction: column;
  
  height: 300px;
  overflow-y: auto;
}


.inverters-list{
  display: flex;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}



.plant-space{
  box-sizing: border-box;
  margin: 5px;
  padding: 5px;
  width: 500px;
  height: 300px;

  border: 1px solid var(--light-grey-half);
}

.plant-space header{
  height: 20px;
}
.plant-content{
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  height: 260px;
  overflow-y: auto;

}

.plant-content p{
  align-items: flex-start;
  justify-content: left;
  width: 220px;
  margin: 0;
  text-align: left;
}

.inverters-list p{
  margin-right: 1rem ;
}