
.App-header {
    background-image: var(--gradient-orange);
    /* min-height: 55px; */
    width: 100%;
    grid-area: header;
    display: grid;
    grid-template-areas: 'scr logo ttl';
    grid-template-columns: 40px 130px auto ;
    font-size: calc(10px + 2vmin);
    color: white;
  }

.App-header h2{
    margin: 0px;
    font-size: 2rem;
}

.App-header a{
  text-decoration: none;
  color: white;
  
}

#header-return{
  grid-area: bck;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}


#header-logo{
  grid-area: logo;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: bisque; */
}

#header-content{
  grid-area: ttl;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3vw;
  /* background-color: bisque; */
}

#header-content a{
  margin: 0 10px 0 10px;
  font-size: 1rem;
}

#header-logo img{
  width: 88px;
}

#header-search{
  grid-area: scr;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer; 
  /* background-color: bisque; */
}


#search-icon{
  width: 20px;
  color: white;
}