#showdata{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.full{
    width: 100%;
    height: 100%;
}

.shared{
    width: 100%;
    height: calc(100vh - 300px);
}

#display-info{
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    /* max-width: 900px; */

    
    overflow-y: auto;
    overflow-x: auto;
    
}


#showdata-space{
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: fit-content;
    height: fit-content;
    /* max-height: calc(100vh - 180px); */
}

.element-display-header{
    box-sizing: border-box;

    color: var(--dark-grey);
    margin-bottom: 5px;
    font-size: 0.85rem;
}
.element-display-value{
    box-sizing: border-box;
    color: var(--dark-grey);
    font-weight: bold;
    font-size: 1.0rem;
}

.display-header-div{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.dot-red {
    height: 0.7rem;
    width: 0.7rem;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    }

.dot-yellow {
    height: 0.7rem;
    width: 0.7rem;
    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
    }
.dot-green {
    height: 0.7rem;
    width: 0.7rem;
    background-color: var(--green-1);
    border-radius: 50%;
    display: inline-block;
    }
    


.element-display{
    box-sizing: border-box;
    min-width: 6rem;
    height: fit-content;
    margin: 2px;
    padding: 5px;

    border: solid;
    border-width: 1px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    border-color: var(--light-grey-half);
}
.generalOption{
    min-width: 200px;
    box-sizing: border-box;
    padding: 0.75rem;
}

.generalOption .element-display-value{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding-bottom: 0.2rem;
    border-bottom: 1px solid var(--light-grey-half);
}


.generalOption .element-display-header{
    font-weight: bolder;
    color: var(--dark-grey);
}

.variable-label{
    font-weight: normal;
}

.variable-value{
    font-weight: bold;
}

.its-info{
    display: flex;
    flex-direction: column;
}

.alignHorizontal{
    display: flex;
    flex-direction: row;
}

#selected-element-content{
    box-sizing: border-box;
    /* height: 200px; */
    width: 98%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    /* border-bottom: solid;
    border-width: 1px;
    border-color: var(--light-grey-half); */
}

#selected-element-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
}